import React from "react";
import logo from "../../assets/seb.png";

const NavBar = () => {
  return (
    <nav className="bg-customGray h-14">
      <div className="mx-auto flex justify-between items-left">
        <div className="flex items-center justify-center items-left">
          <div className="flex items-center bg-customGreen h-14 p-3">
            <img src={logo} className="w-8 h-4" alt="Logo" />
          </div>
          <span className="text-white font-sebfontmedium ml-3">
            Internetbanken privat
          </span>
        </div>
        <div className="flex items-center mr-4"></div>
      </div>
    </nav>
  );
};

export default NavBar;
